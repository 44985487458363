<template>
  <v-footer padless>
    <v-row align="center" justify="center" class="mx-0 footer-top">
      <v-col class="text-center col-12 col-sm-6 align-self-sm-stretch">
        <div v-if="author" class="author-panel">
          <v-img :src="authorPhoto" contain class="author-photo mx-auto" />
          <div class="author-name" v-html="i18n(author.name)"></div>
          <div class="author-title" v-html="i18n(author.title)"></div>
        </div>
      </v-col>
      <v-col class="text-center col-12 col-sm-6 align-self-sm-stretch">
        <v-col class="py-4 text-center" cols="12">
          <div v-if="contacts" class="contacts">
            <a
              v-for="(link, idx) in contacts"
              :key="`cnt${idx}`"
              :href="i18n(link.url)"
              :target="link.target"
              ><v-icon v-if="link.icon" color="#fff">{{ link.icon }}</v-icon
              >{{ i18n(link.title) }}</a
            >
          </div>
          <div v-if="tags" class="tags-block" v-html="i18n(tags)"></div>
        </v-col>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mx-0 footer-bottom">
      <v-col class="text-center col-12 col-sm-6 align-self-sm-stretch">
        <v-col v-if="links" class="text-center links-panel" cols="12">
          <a
            v-for="(link, idx) in links"
            :key="`lnk${idx}`"
            :href="i18n(link.url)"
            >{{ i18n(link.title) }}</a
          >
          <v-spacer />
          <img src="../assets/img/liqpay.png" />
          <img src="../assets/img/mc_visa.png" />
        </v-col>
      </v-col>
      <v-col class="text-center col-12 col-sm-6 align-self-sm-stretch">
        <v-col class="text-center copy-panel" cols="12">
          &copy; {{ new Date().getFullYear() }} —
          <strong>{{ siteTitle }}</strong>
        </v-col>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import { utils } from "../utils.js";

export default {
  name: "Footer",

  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {},

  data: () => ({
    //
  }),
  computed: {
    siteTitle() {
      return process.env.VUE_APP_PAGE_TITLE || "EnglishSVIT";
    },
    author() {
      return this.data ? this.data.author : null;
    },
    tags() {
      return this.data ? this.data.tags : null;
    },
    authorPhoto() {
      return this.author ? require(`../assets/${this.author.photo}`) : null;
    },
    contacts() {
      return this.data ? this.data.contacts : null;
    },
    social() {
      return this.data ? this.data.social : null;
    },
    links() {
      return this.data ? this.data.links : null;
    },
  },

  methods: {
    i18n(text) {
      return utils.i18nText(text);
    },
  },
};
</script>
<style lang="scss">
.v-footer {
  align-items: stretch !important;
  flex-direction: column;
}
.footer-top {
  background: $VUE_APP_SCSS_COLOR2;

  .author-photo {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
  }

  .author-name {
    color: #fff;
    padding-top: 1rem;
    font-weight: bold;
    font-size: 1.25rem;
  }

  .author-title {
    color: #fff;
    font-size: 1rem;
  }

  .contacts {
    a {
      color: #fff;
      display: block;
      font-size: 1rem;
      padding: 0.5rem 0;

      i {
        text-decoration: none;
        display: inline-block;
        margin-right: 0.5rem;
      }
    }
  }

  .tags-block {
    color: #ccc;
    padding: 1.5rem 0;
    h2 {
      display: inline;
      font-weight: normal;
      font-size: 0.75rem;
      color: #ccc;
    }
  }
}
.footer-bottom {
  background: #fff;
  color: $VUE_APP_SCSS_COLOR1;
  .links-panel {
    a {
      display: inline-block;
      margin: 0 0.5rem;
      color: $VUE_APP_SCSS_COLOR1;
      font-size: 1rem;
      line-height: 1rem;
    }
    img {
      display: inline-block;
      height: 1rem;
      margin: 0 0.5rem;
      vertical-align: bottom;
    }
  }
  .copy-panel {
  }
}
</style>
