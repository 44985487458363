<template>
  <div class="d-flex flex-column justify-space-between align-center">
    <div class="w-100">
      <div class="d-flex justify-start align-center">
        <v-img :src="imgUrl(webapp.img)" contain> </v-img>
        <v-card-title
          v-html="i18n(webapp.title)"
          class="justify-start"
        ></v-card-title>
      </div>
      <div>
        <div
          v-for="(feature, idx) in webapp.features"
          :key="`webapp_feature${idx}`"
          class="rounded-box-bl bg-color-0 text-color-1 webapp-feature-btn"
          ><span class="rounded-box-sm-bl bg-color-1 text-color-0 webapp-feature-value"
            >{{ i18n(feature.value)}}</span
          ><div>{{ i18n(feature.title)}}</div></div
        >
      </div>
    </div>
    <div class="w-100">
      <div class="text-center pt-4 pb-2 px-2">
        <v-btn
          color="accent"
          :href="i18n(webapp.url)"
          large
          class="rounded-btn"
        ><span class="text-color-0"
          v-html="i18n(t_tryIt)"></span></v-btn>
      </div>
      <div class="text-center pt-1 pb-2 px-2">
        <v-btn 
          v-if="webapp.siteUrl"
          color="accent"
          :href="i18n(webapp.siteUrl)"
          text
          class="link-btn"
          v-html="i18n(t_learnMore)"
        ></v-btn>
        <div 
          v-else
          class="link-placeholder"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { utils } from "../../utils.js";
export default {
  name: "WebAppItem",

  props: {
    webapp: {
      type: Object,
      required: true,
    },
    texts: {
      type: Object,
      required: true,
    },
  },
  components: {},

  data: () => ({
    //
  }),
  computed: {
    t_tryIt() {
      return this.texts
        ? this.texts.tryIt
        : "Try It";
    },
    t_learnMore() {
      return this.texts
        ? this.texts.learnMore
        : "Learn More";
    },
  },
  methods: {
    i18n(text) {
      return utils.i18nText(text);
    },
    imgUrl(img) {
      return img ? require(`../../assets/${img}`) : "";
    },
  },
};
</script>
<style lang="scss">
  .webapp-feature-btn {
    margin: 1rem 1rem;
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
    text-decoration: none;
    .webapp-feature-value {
      margin: 0.5rem 1rem;
      width: 4rem;
      font-size: 1.5rem;
      line-height: 3rem;
      text-align: center;
      font-weight: 700;
    }
  }
  .link-placeholder {
    height: 2.5rem;
  }

</style>