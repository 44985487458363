<template>
  <div class="features-outer">
    <h3 v-html="i18n(features.title)"></h3>
    <v-row class="features-block">
    <v-col class="col-12 col-md-6">
      <div
        v-for="(item, index) in features.items"
        :key="`feature${index}`"
        align="center"
        class="rounded-box-lg-bl bg-color-0 text-color-1 feature-btn"
        ><span class="rounded-box-bl bg-color-5 text-color-0 feature-icon"
          >{{ i18n(item.icon)}}</span
        ><div v-html="i18n(item.text)"></div></div
      >
    </v-col>
    <v-col class="col-12 col-md-6 d-flex justify-center align-center">
      <div class="text-box col-12 col-sm-8 offset-sm-2" v-html="i18n(features.text)"></div>
    </v-col>
    </v-row>
    <!-- <div class="bottom-link">  
      <a :href="i18n(data.bottom.url)">{{ i18n(data.bottom.text) }}</a>
    </div> -->
  </div>
</template>
<script>
import { utils } from "../utils.js";

export default {
  name: "FeaturesBlock",

  props: {
    features: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showCourses: false,
  }),
  methods: {
    i18n(text) {
      return utils.i18nText(text);
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_mixin.scss";
.features-outer {
  h3 {
    background: $VUE_APP_SCSS_COLOR5;
    color: $VUE_APP_SCSS_COLOR1;
    font-size: 2.5rem;
    text-align: center;
    padding: 2rem 1rem;
  }
}
.features-block {
  background: $VUE_APP_SCSS_COLOR1;
  color: $VUE_APP_SCSS_COLOR0;
  text-align: center;
  padding: 2rem 1rem;

  .feature-btn {
    margin: 1rem 1rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
    padding: 1rem 1rem 1rem 0;
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
    text-decoration: none;
    text-align: left;
    .feature-icon {
      margin: 0.5rem 1rem;
      min-width: 4rem;
      flex-basis: 4rem;
      line-height: 3.5rem;
      font-size: 2.75rem;
      padding-top: 0.5rem;
      text-align: center;
      // vertical-align: middle;
    }
  }
  .text-box {
    font-size: 2.5rem;
    font-weight: 700;
    border: solid 2px $VUE_APP_SCSS_COLOR0;
    border-radius: 3rem;
    padding: 2.5rem 1.5rem;
    margin: 1rem 1rem;
    text-align: left;
  }
  &.no-details {
    min-height: 6rem !important;
  }
}

@include for-xs-and-sm {
  .features-outer {
    h3 {
      font-size: 2rem;
      padding: 1rem 1rem;
    }
  }
}
</style>