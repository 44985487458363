<template>
  <v-container fluid class="webapps-block px-0">
    <h3 v-if="title" v-html="i18n(title)"></h3>
    <v-row class="mt-5 mb-3">
      <v-col v-for="(item, idx) in items" :key="idx">
        <WebAppItem
          :webapp="item"
          :texts="texts"
          :class="`webapp-item ${itemShownCss(item, updater)}`"
          v-observe-visibility="{
            callback: (isVisible, entry) =>
              visibilityChanged(isVisible, entry, item),
            once: true,
            throttle: itemThrottle(idx),
            intersection: {
              threshold: 0.5,
            },
          }"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import WebAppItem from "./items/WebAppItem.vue";
import { utils } from "../utils.js";

export default {
  name: "WebAppsBlock",

  props: {
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: Object,
      required: true
    },
    texts: {
      type: Object,
      required: true
    },
  },
  components: {
    WebAppItem,
  },

  data: () => ({
    updater: 1,
  }),

  methods: {
    visibilityChanged(isVisible, entry, item) {
      // console.log("visibilityChanged", isVisible, entry, item);
      if (item && isVisible && !item.shown) {
        this.showItem(item, isVisible);
      }
    },
    itemShownCss(item, updater) {
      // console.log("itemShownCss", item, updater);
      return item.shown ? "webapp-item-shown" : updater > 0 ? "" : "";
    },
    showItem(item, isVisible) {
      if (item) {
        let q = !!item.shown;
        item.shown = isVisible;
        if (q != item.shown) this.updater++;
      }
    },
    itemThrottle(index) {
      // console.log("itemThrottle", index, this.$vuetify.breakpoint.name);
      return this.$vuetify.breakpoint.name == "lg" ||
        this.$vuetify.breakpoint.name == "xl"
        ? index * 150
        : 150;
    },
    i18n(text) {
      return utils.i18nText(text);
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_mixin.scss";

.webapps-block {
  background: $VUE_APP_SCSS_COLOR1;

  h3 {
    background: $VUE_APP_SCSS_COLOR3;
    color: $VUE_APP_SCSS_COLOR1;
    font-size: 2.5rem;
    text-align: center;
    padding: 2rem 1rem;
  }
  .row {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .webapp-placeholder {
    display: inline-block;
    min-height: 16rem;
  }
  .webapp-item {
    border: solid 2px $VUE_APP_SCSS_COLOR0;
    border-radius: 3rem;
    padding: 1rem 1rem;
    margin: 1rem 1rem;
    height: calc(100% - 2rem);
    opacity: 0;
    transition: opacity 1s ease-in-out;

    &.webapp-item-shown {
      opacity: 1;
    }

    .v-image {
      height: 3rem;
      flex-basis: 3rem;
      flex-grow: 1;
      vertical-align: middle;
    }
    .v-card__title {
      font-size: 2rem;
      line-height: 3rem;
      color: $VUE_APP_SCSS_COLOR0;
      font-weight: 700;
      word-break: break-word;
      text-align: left;
      flex-grow: 10;
    }
    .v-card__text {
      padding-top: 1rem;
      word-break: break-word;
      text-align: left;

      b {
        font-weight: normal;
      }
    }
  }
}

@include for-xs-and-sm {
  .webapps-block {
    h3 {
      font-size: 2rem;
      // padding: 1rem 1rem;
    }

    .row {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    .webapp-item {
      padding: 2rem 0.5rem;
      margin: 1rem 0;
      height: calc(100% - 2rem);

      .v-image {
        height: 4rem;
        flex-basis: 4rem;
      }
      .v-card__title {
        font-size: 2rem;
        line-height: 2rem;
        padding-top: 0;
      }
      .v-card__text {
        padding-top: 1rem;
      }
    }
  }
}
</style>