<template>
  <div class="privacy"></div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Privacy",
  components: {},
};
</script>
