import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: process.env.VUE_APP_SCSS_COLOR1 || "#001c62", //colors.blue.darken3, // "#4c006f"
        secondary: process.env.VUE_APP_SCSS_COLOR2 || "#3F51B5", //colors.indigo.base, // "#001893"
        accent: process.env.VUE_APP_SCSS_COLOR3 || "#673AB7", //colors.deepPurple.base,
        accent2: process.env.VUE_APP_SCSS_COLOR4 || "#673AB7", //colors.deepPurple.base,
        text: process.env.VUE_APP_SCSS_COLOR0 || "#ffffff",
        background: process.env.VUE_APP_SCSS_COLOR1 || "#001c62",
      },
    },
  },
});
