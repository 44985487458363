const content = {
  menu: [
    {
      blockId: "topBlock",
      text: {
        UA: "Платформа",
        EN: "Platform",
      },
    },
    {
      blockId: "webapps",
      text: {
        UA: "Деталі",
        EN: "Details",
      },
    },
    {
      blockId: "features",
      text: {
        UA: "Переваги",
        EN: "Features",
      },
    },
    {
      blockId: "about",
      text: {
        UA: "Наша Місія",
        EN: "Our Mission",
      },
    },
    {
      blockId: "contacts",
      text: {
        UA: "Контакти",
        RU: "Контакты",
        EN: "Contacts",
      },
    },
  ],
  links: [
    {
      url: {
        UA: "https://kids.englishsvit.com/terms-of-use?l=ua",
        RU: "https://kids.englishsvit.com/terms-of-use?l=ru",
        EN: "/terms-of-use",
      },
      text: {
        UA: "Умови Використання",
        RU: "Соглашение",
        EN: "Terms of Use",
      },
    },
    {
      url: {
        UA: "https://kids.englishsvit.com/privacy-policy?l=ua",
        RU: "https://kids.englishsvit.com/privacy-policy?l=ru",
        EN: "/privacy-policy",
      },
      text: {
        UA: "Приватність",
        RU: "Приватность",
        EN: "Privacy Policy",
      },
    },
    {
      url: {
        UA: "https://kids.englishsvit.com/guide?l=ua",
        RU: "https://kids.englishsvit.com/guide?l=ru",
        EN: "https://kids.englishsvit.com/guide?l=en",
      },
      text: {
        UA: "Путівник",
        RU: "Гайд",
        EN: "Guide",
      },
    },
  ],
  topBlock: {
    slogan: {
      UA: "Ви дбаєте про своїх дітей, ми дбаємо про їх освіту",
      RU: "",
      EN: "You care about your children, we care about their education",
    },
    slogan2: {
      UA:
        "Ми допомагаємо обрати курс у відповідності до потреб вашої дитини легко та налаштовуємо ефективний учбовий графік",
      RU: "",
      EN:
        "We help to choose a course according to the needs and age of a child easily and set up a learning schedule",
    },
    downloadApp: {
      UA: "Скоро буде...",
      RU: "",
      EN: "Coming soon...",
    },
    tryOnWeb: {
      UA: "Спробуйте web-версію",
      RU: "",
      EN: "Try a web-version",
    },
    loginBtn: {
      UA: "Вхід до моїх курсів",
      RU: "Вход в мои курсы",
      EN: "Go to my courses",
    },
    coursesButton: {
      UA: "Оберіть Курс",
      RU: "Выберите Курс",
      EN: "Select a Course",
    },
    images: [
      "img/animation2/01.png",
      "img/animation2/01.png",
      "img/animation2/02.png",
      "img/animation2/03.png",
      "img/animation2/04.png",
      "img/animation2/05.png",
      "img/animation2/06.png",
      "img/animation2/07.png",
      "img/animation2/08.png",
      "img/animation2/09.png",
      "img/animation2/10.png",
      "img/animation2/11.png",
      "img/animation2/12.png",
      "img/animation2/13.png",
      "img/animation2/14.png",
      "img/animation2/15.png",
      "img/animation2/16.png",
      "img/animation2/17.png",
      "img/animation2/18.png",
      "img/animation2/19.png",
      "img/animation2/20.png",
      "img/animation2/21.png",
      "img/animation2/22.png",
      "img/animation2/23.png",
      "img/animation2/24.png",
    ],
  },
  webApps: {
    title: {
      UA: "Перевірений концепт",
      EN: "The proven concept",
    },
    apps: [
      {
        img: "img/icons/english.png",
        title: {
          UA: "English",
          EN: "English",
        },
        url: {
          UA: "https://kids.englishsvit.com/courses?l=ua",
          EN: "https://kids.englishsvit.com/courses?l=en",
        },
        siteUrl: {
          UA: "https://englishsvit.com/",
          EN: "https://englishsvit.com/en/",
        },
        features: [
          {
            value: "7",
            title: {
              UA: "Готових Курсів",
              EN: "Ready Courses",
            },
          },
          {
            value: "100+",
            title: {
              UA: "Уроків",
              EN: "Lessons",
            },
          },
          {
            value: "20",
            title: {
              UA: "Типів Вправ",
              EN: "Types of Activities",
            },
          },
        ],
      },
      {
        img: "img/icons/deutsch.png",
        title: {
          UA: "Deutsch",
          EN: "Deutsch",
        },
        url: {
          UA: "https://app.deutschsvit.com/courses?l=ua",
          EN: "https://app.deutschsvit.com/courses?l=en",
        },
        features: [
          {
            value: "2",
            title: {
              UA: "Готових Курса",
              EN: "Ready Courses",
            },
          },
          {
            value: "30+",
            title: {
              UA: "Уроків",
              EN: "Lessons",
            },
          },
          {
            value: "20",
            title: {
              UA: "Типів Вправ",
              EN: "Types of Activities",
            },
          },
        ],
      },
      {
        img: "img/icons/math.png",
        title: {
          UA: "Математика",
          EN: "Math",
        },
        url: {
          UA: "https://kids.mathsvit.com/courses?l=ua",
          EN: "https://kids.mathsvit.com/courses?l=en",
        },
        siteUrl: {
          UA: "https://mathsvit.com/uk",
          EN: "https://mathsvit.com/en",
        },
        features: [
          {
            value: "7",
            title: {
              UA: "Обчислення до 100",
              EN: "Calculate in 100",
            },
          },
          {
            value: "×",
            title: {
              UA: "Таблиця Множення",
              EN: "Multiplication Table",
            },
          },
        ],
      },
    ],
  },
  features: {
    title: {
      UA: "Набагато більше ніж будь-який підручник",
      EN: "Much more than any study book",
    },
    text: {
      UA: "Найпростіше керування навчанням для всієї родини",
      EN: "The easiest family learning management",
    },
    items: [
      {
        icon: "📶",
        text: {
          UA: "Step-by-step learning <b>structures - developing fluency</b>",
          EN: "Step-by-step learning <b>structures - developing fluency</b>",
        },
      },
      {
        icon: "🧭",
        text: {
          UA: "Short and structured explanations <b>highly visualised</b>",
          EN: "Short and structured explanations <b>highly visualised</b>",
        },
      },
      {
        icon: "🎯",
        text: {
          UA: "<b>Precisely prepared content</b> level - aim - value",
          EN: "<b>Precisely prepared content</b> level - aim - value",
        },
      },
      {
        icon: "🌐",
        text: {
          UA: "<b>Human driven - Tech powered Learning</b>",
          EN: "<b>Human driven - Tech powered Learning</b>",
        },
      },
    ],
  },
  texts: {
    tryIt: {
      UA: "Спробуйте",
      EN: "Try It",
    },
    learnMore: {
      UA: "Дізнатись Більше",
      EN: "Learn More",
    },
  },
  about: {
    title: {
      UA: "Наша Місія",
      EN: "Our Mission",
    },
    text: {
      UA: `
      <p>A <b>COMPLETELY NEW SYSTEM</b> to <b>support families</b> with school children.<br/>
      No need to press or punish, no need to organise a learning plan or find a private tutor - <b>EDSvit cares about the effective education</b>.
      </p>
      <p>
      What we have:<br/>
      <ul>
      <li>Precisely prepared <b>COURSES</b> for different ages / school grades;</li>
      <li>System <b>ASSIGNMENTS</b> which <b>suit learners age/level/goal</b>;</li>
      <li>Developing <b>ALL THE SKILLS</b>, learning languages in context and real usage.</li>
      </ul>
      </p>
      <p>
      What we are working on:<br/>
      <ul>
      <li><b>VIRTUAL CLASSES</b> - to provide equal opportunities for everyone - no matter where they live and what level of knowledge they have;</li>
      <li><b>GROUP WORK</b> instead of rough competition, <b>digital personalities</b> for learners to benefit from “peer” group mates,</li>
      <li><b>AI DRIVEN</b> mistakes analysis</li>
      </ul>
      </p>
      <p>
      What we want that our customers <b>GET EASILY</b>:<br/>
      <ul>
      <li><b>FAMILY PROFILE</b> - learners, subjects, courses;</li>
      <li>Setting up <b>GOALS</b> and <b>TIMETABLE</b>;</li>
      <li>Quick <b>CONTROL</b> and clear reports</li>
      </ul> 
      </p>`,
      EN: `
      <p>A <b>COMPLETELY NEW SYSTEM</b> to <b>support families</b> with school children.<br/>
      No need to press or punish, no need to organise a learning plan or find a private tutor - <b>EDSvit cares about the effective education</b>.
      </p>
      <p>
      What we have:<br/>
      <ul>
      <li>Precisely prepared <b>COURSES</b> for different ages / school grades;</li>
      <li>System <b>ASSIGNMENTS</b> which <b>suit learners age/level/goal</b>;</li>
      <li>Developing <b>ALL THE SKILLS</b>, learning languages in context and real usage.</li>
      </ul>
      </p>
      <p>
      What we are working on:<br/>
      <ul>
      <li><b>VIRTUAL CLASSES</b> - to provide equal opportunities for everyone - no matter where they live and what level of knowledge they have;</li>
      <li><b>GROUP WORK</b> instead of rough competition, <b>digital personalities</b> for learners to benefit from “peer” group mates,</li>
      <li><b>AI DRIVEN</b> mistakes analysis</li>
      </ul>
      </p>
      <p>
      What we want that our customers <b>GET EASILY</b>:<br/>
      <ul>
      <li><b>FAMILY PROFILE</b> - learners, subjects, courses;</li>
      <li>Setting up <b>GOALS</b> and <b>TIMETABLE</b>;</li>
      <li>Quick <b>CONTROL</b> and clear reports</li>
      </ul> 
      </p>`,
    },
    bottom: {
      text: {
        UA: "Дізнатись Більше...",
        RU: "Узнать Больше...",
        EN: "Discover More...",
      },
      url: {
        UA: "https://kids.englishsvit.com/guide?l=ua#start",
        RU: "https://kids.englishsvit.com/guide?l=ru#start",
        EN: "https://kids.englishsvit.com/guide?l=en#start",
      },
    },
  },
  icons:
    "🌐🧭🎯🎧🎲📱💻⌚️🖥️⏱️⏰🛠️🖼️📄📁📅🗓️📕📗📘📒✏️🔒🔓📝📶☑️🔈🔇🔉🔊🔔🔕🇩🇪🇬🇧🇺🇸🇺🇦",
  footer: {
    author: {
      photo: "img/po.jpg",
      name: {
        UA: "Ольга Грибанова",
        RU: "Ольга Грибанова",
        EN: "Olga Grybanova",
      },
      title: {
        UA: "Автор і Розробник",
        RU: "Автор и Разработчик",
        EN: "Author and Developer",
      },
    },
    tags: {
      UA: `<h2>англійська для дітей онлайн</h2> &bull; <h2>англійська для школярів онлайн</h2> &bull; <h2>англійська для
      зно онлайн</h2> &bull; <h2>курси англійської мови онлайн</h2>`,
      RU: `<h2>английский для детей онлайн</h2> &bull; <h2>английский для школьников онлайн</h2> &bull; <h2>английский
      для вно онлайн</h2> &bull; <h2>курсы английского языка онлайн</h2>`,
      EN: `<h2>english for school online</h2> &bull; <h2>english for kids online</h2> &bull; <h2>english mobile
      app</h2> &bull; <h2>english courses online</h2>`,
    },
    contacts: [
      {
        url: "tel:+380965286035",
        title: "+380 (96) 528 60 35",
        icon: "mdi-phone",
      },
      // {
      //   url: "https://www.instagram.com/lisosvit/",
      //   title: "@lisosvit",
      //   icon: "mdi-instagram",
      //   target: "_blank",
      // },
      // {
      //   url: "https://www.facebook.com/englishsvitclub/",
      //   title: "@englishsvitclub",
      //   icon: "mdi-facebook",
      //   target: "_blank",
      // },
      {
        url: "mailto:hello@edsvit.com",
        title: "hello@edsvit.com",
        icon: "mdi-at",
      },
    ],
    // social: [{
    //   url: "https://www.instagram.com/lisosvit/",
    //   type: "instagram"
    // }],
    links: [
      {
        url: {
          UA: "https://kids.englishsvit.com/terms-of-use?l=ua",
          RU: "https://kids.englishsvit.com/terms-of-use?l=ru",
          EN: "https://kids.englishsvit.com/terms-of-use?l=en",
        },
        title: {
          UA: "Умови",
          RU: "Соглашение",
          EN: "Terms",
        },
      },
      {
        url: {
          UA: "https://kids.englishsvit.com/privacy-policy?l=ua",
          RU: "https://kids.englishsvit.com/privacy-policy?l=ru",
          EN: "https://kids.englishsvit.com/privacy-policy?l=en",
        },
        title: {
          UA: "Приватність",
          RU: "Приватность",
          EN: "Privacy",
        },
      },
      {
        url: {
          UA: "https://kids.englishsvit.com/guide?l=ua",
          RU: "https://kids.englishsvit.com/guide?l=ru",
          EN: "https://kids.englishsvit.com/guide?l=en",
        },
        title: {
          UA: "Путівник",
          RU: "Гайд",
          EN: "Guide",
        },
      },
    ],
  },
  cookies: {
    warning: {
      UA: `Ми використовуємо файли Cookies, щоб надавати вам найкращій досвід користування сайтом. Продовжуючи користуватись сайтом ви погоджуєтесь із нашими <a href="https://kids.englishsvit.com/privacy-policy?l=ua">Політиками Приватності</a>.`,
      EN: `We use cookies to enhance your experience. By continuing to use our website, you agree to our <a href="https://kids.englishsvit.com/privacy-policy?l=en">Privacy Policy</a>.`,
    },
    agree: {
      UA: `Погодитись`,
      EN: `Agree`,
    },
  },
};

export default content;
