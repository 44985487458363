<template>
  <div>
    <div id="topBlock" ref="topBlock" class="fixed-scroll">
      <v-row class="panel1" align="center" justify="center">
        <v-col
          class="text-center col-12 col-md-8 align-self-md-stretch panel1-1"
        >
          <v-scroll-x-transition :duration="2000">
            <div v-if="showSlogan" class="panel1-1-inner">
              <h2 class="slogan" v-html="i18n(t_slogan)"></h2>
              <h3 class="slogan2" v-html="i18n(t_slogan2)"></h3>
              <AppsBlock
                :webApps="webApps.apps"
                :t_tryOnWeb="t_tryOnWeb"
                :t_downloadApp="t_downloadApp"
                class="panel1-2-inner d-none d-md-block"
              />
            </div>
          </v-scroll-x-transition>
        </v-col>
        <v-col
          class="text-center col-12 col-md-4 align-self-md-stretch panel1-2"
        >
          <v-scroll-x-reverse-transition :duration="2000">
            <AnimatedBlock
              v-if="showBanner"
              :images="animatedImages"
              class="panel1-2-inner animated-img"
            />
            <!-- <div v-if="showBanner" class="panel1-2-inner banner-img">
              <v-btn
                color="accent"
                large
                class="guide-btn glow-btn-2"
                :href="loginUrl"
                >{{ i18n(t_guide) }}</v-btn
              >
            </div> -->
          </v-scroll-x-reverse-transition>
        </v-col>
      </v-row>
      <v-row class="panel2 d-block d-md-none" align="center" justify="center">
        <v-col
          class="text-center col-12"
        >
          <v-scroll-x-transition :duration="2000">
            <div v-if="showSlogan" class="panel2-1-inner">
              <AppsBlock
                :webApps="webApps.apps"
                :t_tryOnWeb="t_tryOnWeb"
                :t_downloadApp="t_downloadApp"
              />
            </div>
          </v-scroll-x-transition>
        </v-col>
      </v-row>
    </div>
    <div ref="webappsBlock" class="fixed-scroll webapps-block">
      <WebAppsBlock id="webapps" :title="webApps.title" :items="webApps.apps" :texts="texts" />
    </div>
    <div ref="featuresBlock" class="fixed-scroll">
      <FeaturesBlock id="features" :features="features" />
    </div>
    <div
      v-if="about"
      id="about"
      class="about-panel fixed-scroll"
    >
      <h3 v-if="about.title" v-html="i18n(about.title)"></h3>
      <v-row
        align="center"
        justify="center"
      >
        <v-col class="text-center col-12 col-md-8">
          <div class="about-inner">
            <!-- <h3
              v-if="about.title"
              class="about-title"
              v-html="i18n(about.title)"
            ></h3> -->
            <div v-html="i18n(about.text)"></div>
            <div v-if="about.bottom" class="bottom-link">
              <v-btn
                color="secondary"
                :href="i18n(about.bottom.url)"
                large
                class="glow-btn-1"
                v-html="i18n(about.bottom.text)"
              ></v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <Footer v-if="footer" id="contacts" :data="footer" class="fixed-scroll" />
  </div>
</template>
<script>
import WebAppsBlock from "./WebAppsBlock";
import FeaturesBlock from "./FeaturesBlock";
import Footer from "./Footer";
import Content from "../content/index";
import { utils } from "../utils.js";
import AnimatedBlock from "./AnimatedBlock.vue";
import AppsBlock from "./AppsBlock.vue";

export default {
  name: "Landing",

  components: {
    WebAppsBlock,
    FeaturesBlock,
    Footer,
    AnimatedBlock,
    AppsBlock
  },

  data: () => ({
    showSlogan: false,
    showBanner: false,
    index: null,
    carouselOptions: {
      arrows: false,
      adaptiveHeight: true,
      autoplay: true,
      dots: true,
      dotsClass: "slick-dots custom-dot-class",
      edgeFriction: 0.35,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  }),

  mounted() {
    window.setTimeout(() => (this.showSlogan = true), 500);
    window.setTimeout(() => (this.showBanner = true), 1000);
  },

  computed: {
    animatedImages() {
      return Content && Content.topBlock ? Content.topBlock.images : [];
    },
    webApps() {
      return Content && Content.webApps
        ? Content.webApps
        : [];
    },
    features() {
      return Content && Content.features ? Content.features : { items: [] };
    },
    services() {
      return Content ? Content.services : {};
    },
    courses() {
      return Content && Content.services ? Content.services.courses : [];
    },
    prices() {
      return Content ? Content.prices : {};
    },
    about() {
      return Content ? Content.about : null;
    },
    footer() {
      return Content ? Content.footer : null;
    },
    gallery() {
      return Content ? Content.gallery : null;
    },
    texts() {
      return Content ? Content.texts : null;
    },
    galleryImages() {
      if (!this.gallery || !this.gallery.images) return null;
      let imgs = this.i18n(this.gallery.images);
      if (!imgs || imgs.length < 1) return null;
      // console.log("galleryImages", this.gallery.images);
      return imgs.map((x) => require(`../assets/${x}`));
    },
    t_slogan() {
      return Content && Content.topBlock
        ? Content.topBlock.slogan
        : process.env.VUE_APP_PAGE_TITLE;
    },
    t_tryOnWeb() {
      return Content && Content.topBlock
        ? Content.topBlock.tryOnWeb
        : "";
    },
    t_downloadApp() {
      return Content && Content.topBlock
        ? Content.topBlock.downloadApp
        : "";
    },    
    t_slogan2() {
      return Content && Content.topBlock ? Content.topBlock.slogan2 : "";
    },
    // t_login() {
    //   return Content && Content.topBlock ? Content.topBlock.loginBtn : "Login";
    // },
    t_guide() {
      return Content && Content.topBlock
        ? Content.topBlock.guideButton
        : "GUIDE";
    },
    t_courses() {
      return Content && Content.topBlock
        ? Content.topBlock.coursesButton
        : "Select a Course";
    },
    i18nLang() {
      return utils.i18nLang();
    },
    loginUrl() {
      return (
        process.env.VUE_APP_APP_URL +
        "login" +
        "?l=" +
        (this.i18nLang || {}).param
      );
    },
  },
  methods: {
    i18n(text) {
      return utils.i18nText(text);
    },
    imgUrl(img) {
      return img ? require(`../assets/${img}`) : "";
    },
    scrollToCourses() {
      let e = this.$refs.coursesBlock;
      if (e) {
        e.scrollIntoView(true);
        window.scrollBy(0, -64);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_mixin.scss";

// .fixed-scroll {
//   margin-top: -3.5rem;
//   margin-bottom: 3.5rem;
//   padding-top: 3.5rem;
// }
.panel1 {
  min-height: 90vh;
  padding: 4rem 3rem 2rem;
  background: $VUE_APP_SCSS_COLOR1;
  .panel1-1 {
    min-height: 90vh;
    .panel1-1-inner {
      padding: 6rem 4rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: 100%;
      .slogan {
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: 700;
        color: $VUE_APP_SCSS_COLOR0;
        word-break: break-word;
        text-align: left;
        margin-bottom: 2rem;
      }
      .slogan2 {
        font-size: 1.75rem;
        line-height: 2.5rem;
        // letter-spacing: 0.15rem;
        color: $VUE_APP_SCSS_COLOR0;
        text-align: left;
        font-weight: 400;
        margin-bottom: 4rem;
        // b {
        //   color: $VUE_APP_SCSS_COLOR_GLOW_CONTRAST3;
        //   text-transform: uppercase;
        // }
      }
    }
  }
  .panel1-2 {
    .panel1-2-inner {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;

      &.animated-img {
        height: 100%;
        width: 100%;
        // max-width: 60vh;
        min-height: 38vh;
        margin-bottom: 2vh;
      }
      &.banner-img {
        // background: transparent url("../assets/img/top_banner2.png")
        //   no-repeat center center;
        // background-size: contain;
        height: 100%;
        width: 100%;
        max-width: 60vh;
        min-height: 38vh;
        margin-bottom: 2vh;
      }
      .courses-btn {
      }
      .guide-btn {
        // position: absolute;
        // margin-top: -50%;
        font-size: 1.5rem;
        line-height: 3rem;
        // min-width: 60%;
        text-transform: none;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
      }
    }
  }
}

.panel2 {
  padding: 2rem 3rem 2rem;
  background: $VUE_APP_SCSS_COLOR1;
}

.slogan-block {
  background: $VUE_APP_SCSS_COLOR1;

  h3 {
    color: $VUE_APP_SCSS_COLOR0;
    text-align: center;
    font-size: 1.75rem;
    padding: 2rem 1rem;
  }
}

.webapps-block {
  min-height: 75vh;
}

.about-panel {
  background: $VUE_APP_SCSS_COLOR1;
  color: $VUE_APP_SCSS_COLOR0;
  min-height: 50vh;
  // padding: 2rem 1rem;

  h3 {
    background: $VUE_APP_SCSS_COLOR2;
    color: $VUE_APP_SCSS_COLOR0;
    font-size: 2.5rem;
    text-align: center;
    padding: 2rem 1rem;
  }
  .about-inner {
    padding: 2rem 1rem;
    .about-title {
      margin-bottom: 1.5rem;
      font-family: $VUE_APP_SCSS_FONT_SPECIAL;
      font-size: 2.25rem;
      line-height: 2.25rem;
      font-weight: normal;
    }
    p {
      text-align: justify;
      font-size: 1.5rem;
      // text-align: left;
    }
    li {
      text-align: left;
      font-size: 1.5rem;
    }
    a {
      color: $VUE_APP_SCSS_COLOR0;
    }
    b {
      color: $VUE_APP_SCSS_COLOR3;
    }
  }
}

@include for-xs-and-sm {
  // .fixed-scroll {
  //   margin-top: -3rem;
  //   margin-bottom: 3rem;
  //   padding-top: 3rem;
  // }

  .panel1 {
    min-height: 30rem;
    padding-bottom: 2rem;

    .panel1-1 {
      // height: 40vh;
      min-height: 25rem;
      padding: 2rem 0.5rem 1rem 0.5rem;
      .panel1-1-inner {
        padding: 0;
        .slogan {
          font-size: 2.5rem;
          line-height: 3rem;
          margin-bottom: 1rem;
          margin-top: 3rem;
        }

        .slogan2 {
          font-size: 1.5rem;
          line-height: 2rem;
        }

      }
    }
    .panel1-2 {
      min-height: 40vh;
      padding: 0;

      .panel1-2-inner {
        margin-top: 1rem;

        &.animated-img {
          height: 100%;
          width: 100%;
          max-width: 80vh;
          min-height: 60vh;
          margin-bottom: 2vh;
        }
        .banner-img {
          max-height: 38vh;
        }
        .guide-btn {
          font-size: 1.25rem;
          line-height: 2.5rem;
          // min-width: 60%;
        }
      }
    }
  }

  // .courses-parallax {
  //   min-height: 65rem;
  // }

  .gallery-parallax {
    min-height: 50rem;
  }

  .about-panel {
    min-height: 20vh;

    h3 {
      font-size: 2rem;
    }
  }
}

@include for-desktop-up {
  .panel1 {
    .panel1-1 {
      .panel1-1-inner {
        .slogan {
          font-size: 3.5rem;
          line-height: 4rem;
          margin-bottom: 2rem;
        }

        .slogan2 {
          font-size: 2rem;
          line-height: 2.25rem;
        }

        .action-box {
          padding: 1rem 1rem;
        }
      }
    }
    .panel1-2 {
      .panel1-2-inner {
        .banner-img {
          width: 90%;
        }
        .guide-btn {
          font-size: 1.75rem;
          line-height: 3.5rem;
          // min-width: 60%;
        }
      }
    }
  }
}
</style>
<!--<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

@media #{map-get($display-breakpoints, 'md')}
  .features-parallax
    min-height: 40rem

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .features-parallax
      min-height: 56rem
</style>-->