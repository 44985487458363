<template>
  <v-snackbar
    fixed
    bottom
    left
    v-model="showCookieBanner"
    color="white"
    timeout="-1"
  >
    <p v-html="i18n(cookies.warning)" class="snackbar"></p>
    <v-btn
      color="secondary"
      large
      class="glow-btn-1"
      @click="agreed"
    >
      {{ i18n(cookies.agree) }}
    </v-btn>
  </v-snackbar>
</template>
<script>
import { utils } from "../utils.js";
import cookie from 'js-cookie';
const COOKIE = 'cookie_agreed';

export default {
  name: "CookiesWarning",

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  components: {},

  data() {
    return {
      showCookieBanner: !cookie.get(COOKIE),
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.showCookieBanner) {
        // Store consent in a cookie with appropriate settings (https://www.cookiechoices.com/)
        this.saveCookies();
      }
    });
  },
  computed: {
    cookies() {
      return this.data;
    },
  },
  methods: {
    i18n(text) {
      return utils.i18nText(text);
    },
    agreed() {
      this.showCookieBanner = false;
      this.saveCookies();
    },
    saveCookies() {
        cookie.set(COOKIE, true, {
          expires: 365, // One year
          sameSite: 'Lax',
          secure: true,
        });
    }
  },
};
</script>
<style lang="scss" scoped>
p.snackbar {
  color: #001c62,
}
</style>
