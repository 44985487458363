<template>
  <div class="animated-block" :style="`background-image:url('${image}')`">
    <div class="image-cache">
      <img
        v-for="(img, idx) in images"
        :key="`imgcache${idx}`"
        :src="imgUrl(img)"
        @load="onLoad"
      />
    </div>
  </div>
</template>
<script>
import { utils } from "../utils.js";

export default {
  name: "AnimatedBlock",

  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    // this.start();
  },
  data: () => ({
    frameInterval: 1000,
    timer: null,
    frameNumber: 0,
    loaded: 0,
  }),
  computed: {
    framesCount() {
      return this.images ? this.images.length : 0;
    },
    image() {
      var img =
        this.images &&
        this.frameNumber >= 0 &&
        this.frameNumber < this.images.length
          ? this.images[this.frameNumber]
          : "";
      return this.imgUrl(img);
    },
  },
  methods: {
    start() {
      this.stop();
      this.timer = window.setInterval(() => {
        this.nextFrame();
      }, this.frameInterval);
    },
    stop() {
      if (this.timer) window.clearInterval(this.timer);
      this.timer = null;
    },
    nextFrame() {
      if (this.frameNumber >= this.framesCount - 1) this.frameNumber = 0;
      else this.frameNumber++;
    },
    imgUrl(img) {
      return img ? require(`../assets/${img}`) : "";
    },
    onLoad() {
      this.loaded++;
      // console.log("onLoad", idx, this.loaded, this.images.length);
      if (this.images && this.loaded >= this.images.length) {
        this.start();
      }
    },
    i18n(text) {
      return utils.i18nText(text);
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_mixin.scss";
.animated-block {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: background-image 0.5s ease-in-out;
}
.image-cache {
  width: 1px;
  height: 1px;
  visibility: hidden;
  overflow: hidden;
}
</style>