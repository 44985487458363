<template>
  <v-app>
    <v-app-bar fixed color="secondary" dark>
        <v-img
          :src="logoHeader"
          class="top-logo flex-grow-0 flex-shrink-1"
          contain
          position="left"
        />
      <v-spacer></v-spacer>
      <div class="flex-grow-1 flex-shrink-0 text-right pr-7">
        <v-btn
          v-for="lang in langs"
          :key="lang.abbr"
          icon
          dense
          :href="lang.url"
          :class="{ selected: selectedLang(lang.key) }"
          class="top-lang-btn"
        >
          {{ lang.short }}
        </v-btn>
        <div
          class="ts_navbar_btn ts_navbar_menu"
          :class="{ open: mainMenuIsOpen }"
          @click="openMainMenu"
        >
          <i></i>
          <i></i>
          <i></i>
        </div>
        <div class="ts_navbar ts_navbar_menu" :class="{ open: mainMenuIsOpen }">
          <v-btn
            v-for="(item, index) in menuItems"
            text
            block
            @click.prevent="scrollTo(item.blockId)"
            :key="`mi${index}`"
            class="my-3"
          >
            {{ i18n(item.text) }}
          </v-btn>
          <hr />
          <v-btn
            v-for="(item, index) in menuLinks"
            text
            block
            @click.prevent="openLink(i18n(item.url))"
            :key="`ml${index}`"
            class="my-3"
          >
            {{ i18n(item.text) }}
          </v-btn>
          <!-- <router-link
            v-if="showSignUp"
            :to="{ name: 'SignUp' }"
            class="clickable"
          >
            <template v-if="myLang == 'UA'">Реєстрація</template>
            <template v-else-if="myLang == 'RU'">Регистрация</template>
            <template v-else>Sign Up</template>
          </router-link>
          <hr />
          <router-link :to="{ name: 'Courses' }" class="clickable">
            <template v-if="myLang == 'UA'">Всі Курси</template>
            <template v-else-if="myLang == 'RU'">Все Курсы</template>
            <template v-else>All Courses</template>
          </router-link>
          <router-link :to="{ name: 'Products' }" class="clickable">
            <template v-if="myLang == 'UA'">Підписка та Ціни</template>
            <template v-else-if="myLang == 'RU'">Подписка и Цены</template>
            <template v-else>Membership and Prices</template>
          </router-link> -->
          <div class="bottom">
            <!-- <router-link :to="{ name: 'About' }" class="clickable">About</router-link>
          <router-link :to="{ name: 'Terms' }" class="clickable">Terms of Use</router-link>

          <hr />-->
            <v-btn text block @click.prevent="forceReload"> Reload </v-btn>
            <small>build: {{ buildVersion }}</small>
          </div>
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <Landing />
      <CookiesWarning _v-if="!cookiesAccepted" :data="cookies" />
    </v-main>
  </v-app>
</template>

<script>
import Landing from "./components/Landing";
import CookiesWarning from "./components/CookiesWarning";
import { utils } from "./utils.js";
import Content from "./content/index.js";

export default {
  name: "App",

  components: {
    Landing, 
    CookiesWarning
  },
  mounted() {
    // let hubspot = document.createElement("script");
    // hubspot.setAttribute("src", "//js-na1.hs-scripts.com/20258942.js");
    // hubspot.setAttribute("async", true);
    // hubspot.setAttribute("defer", true);
    // document.body.appendChild(hubspot);
  },
  data: () => ({
    mainMenuIsOpen: false,
    cookiesAcceptedVar: "cookiesAccepted",
  }),
  computed: {
    logoHeader() {
      return require(`./assets/${process.env.VUE_APP_HEADER_LOGO ||
        "logo.png"}`);
    },
    langs() {
      // console.log("langs", utils);
      return utils.langSupported;
    },
    buildVersion() {
      // let d = Date.parse(document.documentElement.dataset.buildTimestamp);
      // return moment(d).format("YYYY-MM-DD HH:mm:ss");
      return document.documentElement.dataset.buildTimestamp;
    },
    menuItems() {
      return Content ? Content.menu || [] : [];
    },
    menuLinks() {
      return Content ? Content.links || [] : [];
    },
    cookies() {
      return Content ? Content.cookies || {} : {};
    },
    cookiesAccepted() {
      return localStorage.getItem(this.cookiesAcceptedVar);
    }
  },
  methods: {
    selectedLang(langKey) {
      if (!langKey || !window.LANG) return false;
      return langKey.toUpperCase() == window.LANG.toUpperCase();
    },
    openMainMenu() {
      this.mainMenuIsOpen = !this.mainMenuIsOpen;
    },
    forceReload() {
      document.location.reload(true);
    },
    i18n(text) {
      return utils.i18nText(text);
    },
    scrollTo(blockId) {
      this.mainMenuIsOpen = false;
      let e = document.getElementById(blockId);
      console.log("scrollTo", blockId, e);
      if (e) {
        e.scrollIntoView(true);
        window.scrollBy(0, -64);
      }
    },
    openLink(url) {
      if (url) window.location = url;
    },
    setCookiesAccepted(value) {
      try {
        localStorage.setItem(this.cookiesAcceptedVar, value);
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
<style lang="scss">
@import "./assets/scss/main.scss";
@import "./assets/scss/_mixin.scss";

.top-logo {
  height: 100%;
}
.v-btn.selected {
  color: $VUE_APP_SCSS_COLOR3 !important;
}
.top-lang-btn {
  width: 2.25rem !important;
  height: 2.25rem !important;
}
.row {
  margin: 0 !important;
}

.ts_navbar_btn {
  z-index: 10001;
  cursor: pointer;
  display: inline-block;
  position: fixed;
  top: 1.25rem;
  right: 0.5rem;
  i {
    transition: transform 0.2s;
    display: block;
    width: 28px;
    height: 3px;
    background: #fff;
    z-index: 100;
  }
  i + i {
    margin-top: 7px;
  }

  &.open {
    i:first-child {
      transform: rotate(-45deg) translate(-7px, 7px);
    }
    i:first-child + i {
      visibility: hidden;
    }
    i:last-child {
      transform: rotate(45deg) translate(-7px, -7px);
    }
  }
}
.ts_navbar {
  display: none;
  position: fixed;
  right: 0px;
  top: 0;
  z-index: 10000;
  width: 15rem;
  max-width: 80vw;
  height: 100vh;
  background: $VUE_APP_SCSS_COLOR_MENU_SHADE;
  color: #fff;
  padding-top: 56px;
  transition: all 0.3s;
  line-height: 1.3;

  &.open {
    display: block;
  }

  h3 {
    padding: 0 1rem;
    color: #2df;
    font-size: 1.5rem;
    text-align: center;
  }

  hr {
    border-color: #2df;
    // margin-bottom: 2rem;
  }

  a {
    color: #ddd;
    display: block;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    text-align: right;
    text-decoration: none;
  }

  a:hover {
    color: #fff;
    text-decoration: none;
  }

  .bottom {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    padding: 0.5rem;
    text-align: center;

    small {
      color: #ccc;
    }
  }
}
@include for-phone-only {
  .ts_navbar_btn {
    top: 1rem;
  }
}
</style>
