export const utils = {
  langSupported: [
    {
      key: "EN",
      abbr: "EN",
      url: "/",
      param: "en",
      title: "English",
      short: "Eng",
    },
    {
      key: "UA",
      abbr: "UA",
      url: "/ua",
      param: "ua",
      title: "Українська",
      short: "Укр",
    },
    // {
    //   key: "RU",
    //   abbr: "RU",
    //   url: "/ru",
    //   param: "ru",
    //   title: "Русский",
    //   short: "Рус"
    // }
  ],
  fromJSON: function(json) {
    let res = null;
    try {
      json = (json || "null").replace(/[\n\r]/g, " ");
      res = JSON.parse(json);
    } catch (error) {
      console.error(error);
    }
    return res;
  },
  toJSON: function(obj) {
    return JSON.stringify(obj);
  },
  i18nLang: function(lang) {
    console.log("i18nLang", this);
    if (!lang && window.LANG) lang = window.LANG || this.langSupported[0].key;
    let i = this.langSupported.findIndex((x) => x.key == lang);
    return i >= 0 && i < this.langSupported.length
      ? this.langSupported[i]
      : this.langSupported[0];
  },
  i18nText: function(text, lang) {
    if (!lang && window.LANG) lang = window.LANG || this.langSupported[0].key;
    if (!text) return "";
    let o = text;
    if (typeof o == "string") {
      if (text.startsWith("{") && text.endsWith("}")) {
        o = this.fromJSON(text);
      } else return o;
    }
    if (o) {
      if (o[lang]) return o[lang];
      if (o.length > 0) return o[0];
      let k = Object.keys(o);
      if (k && k.length > 0 && o[k[0]]) return o[k[0]];
    }
    return text;
  },
};
