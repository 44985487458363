<template>
  <div class="apps-outer">
    <v-row align="center" justify="center">
      <v-col
        v-if="webApps && webApps.length"
        class="text-center col-12 col-sm-6 align-self-sm-stretch"
      >
        <h3 class="subheader" v-html="i18n(t_tryOnWeb)"></h3>
        <div>
          <a
            v-for="webApp in webApps"
            :key="webApp.logoImg"
            :href="i18n(webApp.url)"
            large
            class="rounded-box-bl bg-color-0 text-color-1 web-app-btn"
            ><span class="rounded-box-sm-bl bg-color-1 text-color-0 web-app-icon"
              ><img :src="imgUrl(webApp.img)" /></span
            ><div>{{ i18n(webApp.title)}}</div></a
          >
        </div>
      </v-col>
      <v-col
        class="text-center col-12 col-sm-6 align-self-sm-stretch"
      >
        <h3 class="subheader" v-html="i18n(t_downloadApp)"></h3>
        <div class="action-box">
          <a href="#" @click.prevent="comingSoonPopup('iOS')" class="appStoreBtn"
            ><img
              src="../assets/img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
            /></a
          >
          <a href="#" @click.prevent="comingSoonPopup('Android')" class="googlePlayBtn"
            ><img
              src="../assets/img/google-play-badge.png"
            /></a
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { utils } from "../utils.js";

export default {
  name: "AppsBlock",

  props: {
    webApps: {
      type: Array,
      required: true,
    },
    t_tryOnWeb: {
      type: Object,
      required: true,
    },
    t_downloadApp: {
      type: Object,
      required: true,
    },
  },
  methods: {
    i18n(text) {
      return utils.i18nText(text);
    },
    imgUrl(img) {
      return img ? require(`../assets/${img}`) : "";
    },
    comingSoonPopup(os) {
      window.alert(`App for ${os} is coming soon...`);
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_mixin.scss";
.apps-outer {
  .subheader {
    color: $VUE_APP_SCSS_COLOR0;
  }
  .action-box {
    padding: 1rem 1rem;
    .appStoreBtn {
      width: 15rem;
      margin: 2rem 0rem;
      img { 
        width: 11rem;
      }
    }
    .googlePlayBtn {
      width: 15rem;
      margin: 2rem 0rem;
      img { 
        width: 13rem;
      }
    }
  }
  .web-app-btn {
    margin: 1rem 1rem;
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
    text-decoration: none;
    .web-app-icon {
      margin: 0.5rem 1rem;
      width: 3rem;
      line-height: 3rem;
      img {
        width: 2rem;
        height: 2rem;
        vertical-align: middle;
      }
    }

    &:hover {
      background: $VUE_APP_SCSS_COLOR5;
    }
  }
}
</style>