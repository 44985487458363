var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"webapps-block px-0",attrs:{"fluid":""}},[(_vm.title)?_c('h3',{domProps:{"innerHTML":_vm._s(_vm.i18n(_vm.title))}}):_vm._e(),_c('v-row',{staticClass:"mt-5 mb-3"},_vm._l((_vm.items),function(item,idx){return _c('v-col',{key:idx},[_c('WebAppItem',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
          callback: function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, item); },
          once: true,
          throttle: _vm.itemThrottle(idx),
          intersection: {
            threshold: 0.5,
          },
        }),expression:"{\n          callback: (isVisible, entry) =>\n            visibilityChanged(isVisible, entry, item),\n          once: true,\n          throttle: itemThrottle(idx),\n          intersection: {\n            threshold: 0.5,\n          },\n        }"}],class:("webapp-item " + (_vm.itemShownCss(item, _vm.updater))),attrs:{"webapp":item,"texts":_vm.texts}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }